.section {
  margin: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 14px;
  background-color: white;
  color: #242424;
}

.darksection {
  margin: 1rem;
  padding: 1rem;
  color: white;
}

table {
  margin: 1rem;
  padding: 1rem;
  height: 100%;
  border: 1rem solid #242424;
  color: #242424;
  font-weight: 600;
  border-radius: 2rem;
  background-image: url("../assets/beer-background.jpg");
  background-position: center;
  background-size: cover;
  background-color: tan;
  background-blend-mode: overlay;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.half {
  width: 37.5rem;
}

.half ul {
  list-style: circle;
  margin: 0 0.5rem;
}
.half li {
  margin: 0.5rem;
  font-weight: 600;
}

.certificationpic {
  width: 37.5rem;
  height: 18rem;
  background-image: url("../assets/certification.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 3rem;
  background-color: #242424;
  border-radius: 1rem;
}

.info_bubble {
  width: 35rem;
  height: fit-content;
  background-color: tan;
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  margin: 1rem;
  color: #242424;
  border: 1rem solid #242424;
  border-radius: 2rem;
}

@media (max-width: 540px) {
  table {
    margin: 0.25rem;
    padding: 0.5rem;
  }

  .certificationpic {
    height: 16rem;
    background-position-x: 1rem;
  }
}
