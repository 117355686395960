.nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  background-color: #242424;
  opacity: 70%;
}

.list {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.list a {
  text-decoration: none;
  color: var(--color-primary-400);
  opacity: 100% !important;
}

.list a:hover,
.list a.active {
  color: var(--color-primary-800);
  text-decoration: underline;
}
