* {
  box-sizing: border-box;
}

:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --color-gray-100: #f4f3f1;
  --color-gray-200: #dddbd8;
  --color-gray-300: #ccc9c6;
  --color-gray-400: #aeaba7;
  --color-gray-500: #8a8784;
  --color-gray-600: #656360;
  --color-gray-700: #4b4a47;
  --color-gray-800: #31302e;
  --color-gray-900: #1f1d1b;

  --color-primary-100: #fcf3e1;
  --color-primary-200: #fceccd;
  --color-primary-300: #fae1af;
  --color-primary-400: #fbd997;
  --color-primary-500: #ffd37c;
  --color-primary-600: #f9c762;
  --color-primary-700: #fbc14d;
  --color-primary-800: #fab833;
  --color-primary-900: #f6ad1b;
}

body {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

main {
  margin: 2rem auto 0;
  text-align: center;
}
