.section {
  margin: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 14px;
  background-color: white;
  color: #242424;
}
.darksection {
  margin: 1rem;
  padding: 1rem;
  color: white;
}

.info_bubble {
  width: 35rem;
  height: fit-content;
  background-color: tan;
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  margin: 1rem;
  color: #242424;
  border: 1rem solid goldenrod;
  border-radius: 2rem;
}

.flex {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.half {
  width: 60%;
  text-align: right;
  margin: auto;
}

.half ul {
  width: 80%;
  text-align: center;
  margin: auto;
  list-style: circle;
}

.half li {
  margin: 0.5rem 0.1rem;
  font-weight: 500;
}

.contacthalf {
  width: 40%;
  text-align: center;
}

.aboutpic {
  width: 25rem;
  height: 25rem;
  background-image: url("../assets/mara-pic.jpg");
  background-position: center;
  background-size: 25rem;
  border-radius: 20rem;
  border: 1rem solid #242424;
  margin: auto;
}

.contactpic {
  width: 40%;
  background-image: url("../assets/contactpic.jpg");
  background-position: center;
  background-size: cover;
}

.linkbutton {
  cursor: pointer;
  font: inherit;
  border: none;
  background-color: goldenrod;
  color: #242424;
  padding: 0.75rem 3rem;
  border-radius: 25px;
  font-weight: bold;
  line-height: 6rem;
}

.linkbutton:hover,
.linkbutton:active {
  background-color: #242424;
  color: goldenrod;
}

.servicetab {
  width: 25rem;
  color: #242424;
  padding: 1rem;
  border-radius: 2rem;
  margin-top: 1rem;
}

.servicetab ul {
  width: 80%;
  margin: auto;
  padding: 0.5rem 0rem;
}

.servicetab li {
  list-style: circle;
  font-style: italic;
  font-weight: 600;
  margin: 0rem 0.5rem;
}

.gold {
  background-color: #ea982d;
}

.pale {
  background-color: #e3e3e5;
}

.brown {
  background-color: lightgoldenrodyellow;
}

.contacthalf h3 {
  margin-bottom: 0.25rem;
}

@media (max-width: 863px) {
  .servicetab {
    width: 90%;
    margin: 1rem auto;
  }
}

@media (max-width: 994px) {
  .contactpic {
    display: none;
  }
  .contacthalf,
  .half {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 540px) {
  .linkbutton {
    padding: 0.75rem 1.25rem;
    width: 100%;
  }
}
