.faq {
  color: #242424;
  margin: 15px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faq.open .faq-question {
  margin-bottom: 15px;
}

.faq.open .faq-question::after {
  content: "-";
}

.faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}
