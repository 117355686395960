.section {
  margin: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 14px;
  background-color: white;
  color: #242424;
}

.flex {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.contacthalf {
  width: 40%;
  text-align: center;
}

.contactpic {
  width: 40%;
  background-image: url("../assets/contactpic.jpg");
  background-position: center;
  background-size: cover;
}

.faqs {
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  max-width: 40rem;
}

@media (max-width: 994px) {
  .contactpic {
    display: none;
  }
  .contacthalf,
  .half {
    width: 100%;
    text-align: center;
  }
}
