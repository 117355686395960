.section {
  margin: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 14px;
  background-color: white;
  color: #242424;
}
.darksection {
  margin: 1rem;
  padding: 1rem;
  color: white;
}

.flex {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.half {
  width: 35rem;
  text-align: right;
  margin: auto;
}

.half ul {
  width: 80%;
  text-align: center;
  margin: auto;
  list-style: circle;
}

.half li {
  margin: 0.5rem 0.1rem;
  font-weight: 500;
}

.aboutpic {
  width: 25rem;
  height: 25rem;
  background-image: url("../assets/pic1.jpg");
  background-position: center;
  background-size: 31rem;
  border-radius: 20rem;
  border: 1rem solid #242424;
  margin: auto;
}

.aboutpic2 {
  width: 35rem;
  height: 27.5rem;
  background-image: url("../assets/pic2.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 20rem;
  border: 1rem solid #242424;
  margin: auto;
}

.aboutpic4 {
  width: 35rem;
  height: 20rem;
  margin: auto;
  background-image: url("../assets/wedding-pic.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 20rem;
  border: 1rem solid #242424;
  margin: auto;
}

@media (max-width: 640px) {
  .half {
    width: 90%;
    text-align: center;
  }
  .aboutpic2 {
    display: none;
  }
  .aboutpic4 {
    width: 90%;
  }
}
