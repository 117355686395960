.logos {
  color: white;
}
.logos h2 {
  font-size: 1rem;
  color: grey;
  margin: 0.1rem 3rem;
  text-align: right;
  font-style: italic;
}

.logolist {
  height: 5rem;
  display: flex;
  justify-content: space-evenly;
  overflow: hidden;
}
.logopic {
  height: 5rem;
  width: 8rem;
  margin: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.logopic1 {
  background-image: url("../assets/bostonpizza.png");
}
.logopic2 {
  width: 12rem;
  background-size: cover;
  background-image: url("../assets/mrmikes-logo.png");
}
.logopic3 {
  background-image: url("../assets/pint-logo.png");
}
::marker {
  color: transparent;
}
