.header {
  width: 100%;
  height: 40rem;
  background-image: url("../assets/beer_banner.png");
  background-position: center;
  background-size: cover;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flexcolumn {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.flexcolumn span {
  color: #242424;
  font-size: 2rem;
  line-height: 2rem;
}

.main-image {
  width: 100%;
  height: 25rem;
  z-index: 0;
  overflow: hidden;
}

.main-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo-image {
  height: 100%;
  width: 50%;
}

.logo-image h1 {
  display: none;
}

.logo-image img {
  margin-top: 5rem;
  height: 100%;
  width: 100%;
}

.header h3 {
  font-size: 3.5rem;
  color: #242424;
  line-height: 3.5rem;
  font-style: italic;
  text-align: center;
}

@media (max-width: 994px) {
  .header .flex {
    flex-direction: column;
    align-items: center;
  }
  .header h3 {
    font-size: 2rem;
    line-height: 2rem;
  }
  .logo-image img {
    margin-top: 0;
  }
}

@media (max-width: 780px) {
  .logo-image {
    width: 75%;
  }
}

@media (max-width: 540px) {
  .logo-image {
    width: 85%;
  }
  .flexcolumn {
    width: 90%;
    margin-bottom: 1rem;
  }
}
